<template>
    <div class="forgotten-password-container">
        <h1>{{ $t("forgottenPassword.title") }}</h1>
        <p v-if="!showThirdPart">{{ $t("forgottenPassword.giveMail") }}</p>
        <div class="first-part" v-if="showFirstPart">
            <div class="input-wrapper">
                <p>{{ $t("forgottenPassword.mailOrId") }}</p>
                <BasicInput class="input" name="mailOrId" v-model="id" />
            </div>
            <div class="button-wrapper">
                <BasicButton
                    class="button"
                    :color="id ? 'orange' : 'grey'"
                    :disabled="!id"
                    @click="sendMail"
                >
                    {{ $t("button.validate") }}
                </BasicButton>
                <BasicButton class="button" color="blue" @click="goTo('Login')">
                    {{ $t("button.cancel") }}
                </BasicButton>
            </div>
        </div>
        <div class="second-part" v-if="showSecondPart">
            <div class="code-wrapper">
                <p>{{ $t("forgottenPassword.code") }}</p>
                <BasicInput
                    class="code"
                    name="code"
                    v-model="code"
                    placeholder="123456"
                />
            </div>
            <div class="password-container">
                <div
                    class="password-security"
                    :class="isValidCode ? 'valid' : 'invalid'"
                >
                    <p>{{ $t("register.anssiPassword") }}</p>
                    <p class="password-rule">
                        {{ $t("register.passwordRule1") }},
                        {{ $t("register.passwordRule2") }},
                        {{ $t("register.passwordRule3") }},
                        {{ $t("register.passwordRule4") }},
                        {{ $t("register.passwordRule5") }} (- _ @ $ ! ? * / # .
                        , +)
                    </p>
                </div>
                <div
                    class="password-wrapper"
                    :class="isValidCode ? 'valid' : 'invalid'"
                >
                    <p>{{ $t("forgottenPassword.newPassword") }}</p>
                    <PasswordInput
                        class="password"
                        :disabled="!isValidCode"
                        name="new-password"
                        v-model="password"
                    />
                </div>
                <div
                    class="password-wrapper"
                    :class="isValidCode ? 'valid' : 'invalid'"
                >
                    <p>{{ $t("forgottenPassword.confirmNewPassword") }}</p>
                    <PasswordInput
                        class="password"
                        :disabled="!isValidCode"
                        name="confirm-password"
                        v-model="confirmPassword"
                        :needErrorMessage="false"
                    />
                </div>
                <p
                    class="error"
                    v-if="
                        isTheSamePassword !== true &&
                        isTheSamePassword !== false
                    "
                >
                    {{ isTheSamePassword }}
                </p>
                <p class="error" v-if="errorMessage">
                    {{ errorMessage }}
                </p>
            </div>
            <div class="button-wrapper">
                <BasicButton
                    class="button"
                    :color="
                        isTheSamePassword === true && isValidCode === true
                            ? 'orange'
                            : 'grey'
                    "
                    :disabled="
                        isTheSamePassword !== true || isValidCode !== true
                    "
                    @click="changePassword"
                >
                    {{ $t("button.validate") }}
                </BasicButton>
                <BasicButton class="button" color="blue" @click="goTo('Login')">
                    {{ $t("button.cancel") }}
                </BasicButton>
            </div>
        </div>
        <div class="thrid-part" v-if="showThirdPart">
            <p>{{ $t("forgottenPassword.success") }}</p>
            <div class="button-wrapper">
                <BasicButton class="button" @click="goTo('Login')">
                    {{ $t("button.close") }}
                </BasicButton>
            </div>
        </div>
    </div>
</template>

<script>
import BasicInput from "@/components/basic/BasicInput.vue"
import BasicButton from "@/components/basic/BasicButton.vue"
import PasswordInput from "@/components/basic/PasswordInput.vue"

import {
    sendResetCodePassword,
    resetPassword,
} from "@/services/intratone/auth.js"
import { getPasswordSecurityLevel } from "@/services/intratone/account.js"

export default {
    name: "ForgottenPassword",
    components: {
        BasicInput,
        BasicButton,
        PasswordInput,
    },
    data() {
        return {
            id: null,
            code: null,
            password: null,
            confirmPassword: null,
            showFirstPart: true,
            showSecondPart: false,
            showThirdPart: false,
            errorMessage: null,
        }
    },

    watch: {
        id() {
            this.errorMessage = null
        },

        code() {
            this.errorMessage = null
        },
        password() {
            this.errorMessage = null
        },

        confirmPassword() {
            this.errorMessage = null
        },
    },

    computed: {
        isTheSamePassword() {
            if (this.password && this.confirmPassword) {
                if (this.confirmPassword === this.password) {
                    return true
                } else {
                    return this.$t("forgottenPassword.error.password")
                }
            } else {
                return false
            }
        },

        isValidPassword() {
            const regex = new RegExp(
                "^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)(?=.*[-_@$!?*/#.,+]).{12,}$"
            )
            return regex.test(this.password)
        },

        isValidCode() {
            return this.code && this.code.length >= 6
        },

        param() {
            return this.$store.getters["auth/getParam"]
        },
    },

    methods: {
        goTo(route) {
            this.$router.push({ name: route })
        },

        async sendMail() {
            await sendResetCodePassword({
                mode: this.param,
                data: this.id,
            }).then((response) => {
                if (response.error == 0) {
                    this.showFirstPart = false
                    this.showSecondPart = true
                }
            })
        },

        async changePassword() {
            this.errorMessage = null

            if (this.isTheSamePassword) {
                if (!this.isValidPassword) {
                    this.errorMessage = this.$t(
                        "forgottenPassword.error.notRespect"
                    )
                    return
                }

                await getPasswordSecurityLevel({
                    user: this.id,
                    pwd: this.password,
                }).then((response) => {
                    if (response.error != 0) {
                        return Promise.reject(response.message)
                    }
                })

                await resetPassword({
                    mode: this.param,
                    data: this.id,
                    code: this.code,
                    newPwd: this.password,
                    newPwdConfirm: this.confirmPassword,
                }).then((response) => {
                    if (response.error != 0) {
                        if (response.form.code) {
                            this.errorMessage = this.$t(
                                "forgottenPassword.error.codeProblem"
                            )
                        }
                    } else {
                        this.showFirstPart = false
                        this.showSecondPart = false
                        this.showThirdPart = true

                        setTimeout(() => {
                            this.goTo("Login")
                        }, 10000)
                    }
                })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";

.forgotten-password-container {
    display: flex;
    flex-direction: column;
    gap: 2em;
    padding: 0 3em;

    h1,
    p {
        margin: 0;
    }

    h1 {
        font-family: $font_avenir_heavy;
        font-size: $semiBig;
    }

    .first-part {
        display: flex;
        flex-direction: column;
        gap: 2em;

        .input-wrapper {
            display: flex;
            align-items: center;
            gap: 1em;

            P {
                min-width: max-content;
            }

            .input {
                width: 100%;
            }
        }
        .button-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1em;
        }
    }

    .second-part {
        display: flex;
        flex-direction: column;
        gap: 2em;
        .code-wrapper {
            display: flex;
            align-items: center;
            gap: 2em;
            p {
                min-width: max-content;
            }
            .code {
                width: 100%;
            }
        }

        .password-container {
            display: flex;
            flex-direction: column;
            gap: 1em;
            .password-security {
                text-align: left;
                transition: all 0.2s;

                .password-rule {
                    font-family: $font_avenir_heavy;
                }

                p {
                    margin: 0;
                }
            }

            .password-wrapper {
                display: flex;
                align-items: center;
                gap: 2em;
                transition: all 0.2s;

                p {
                    width: 100%;
                    max-width: 30%;
                }

                .password {
                    width: 70%;
                }
            }

            .error {
                color: $tag-red;
            }
        }
        .button-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1em;
        }
    }

    .thrid-part {
        display: flex;
        flex-direction: column;
        gap: 2em;

        .button-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1em;
        }
    }
}

.valid {
    color: $blue-neutral;
}

.invalid {
    color: $grey-neutral;
}
</style>
